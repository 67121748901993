import React from 'react';
import { ThemeProvider } from '@gcc/autobahn-components';
import Experience from './Experience';
import useFixedHarmonyTheme from '../../hooks/_useFixedHarmonyTheme';

export const MicroAppExperience = () => {
  const themeHarmony = useFixedHarmonyTheme();
  return (
    <ThemeProvider theme={themeHarmony}>
      <Experience />
    </ThemeProvider>
  );
};

MicroAppExperience.propTypes = {
};

MicroAppExperience.displayName = 'MicroAppExperience';

MicroAppExperience.defaultProps = {
  name: '',
};
