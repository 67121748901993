/* eslint-disable max-len */
import React from 'react';
import ReactDOM from 'react-dom';
import { ExperienceWithRoutes, peerDependencies } from '@gcc/autobahn-experience';
import * as styled from 'styled-components';
import { CustomMeasureAndInstallExpConfiguration } from '../../utility/customMeasureAndInstallExpConfiguration';
import { Error } from '../Error';
import { analyticsInitPcp } from '../../utility/analytics';

// Expose micro app peer dependencies.
peerDependencies(React, ReactDOM, styled);
window.__micro_frontend__ = {};
window.__micro_frontend__ = {
  settings: { microFrontendBaseUrl: CustomMeasureAndInstallExpConfiguration.AUTOBAHN_BASE_URL,
    cdnBaseUrl: CustomMeasureAndInstallExpConfiguration.MicroAppBundleBaseUrl }
};

window.globalConfig = window.globalConfig || {};
window.globalConfig.siteTheme = 'thd';
window.globalConfig.siteId = 11;
analyticsInitPcp();

const Experience = () => (
  <>
    <ExperienceWithRoutes siteId={11} productLineId={1} errorHandler={Error} />
  </>
);

export default Experience;
